import { Vue } from 'nuxt-property-decorator'

export enum NODE_ENV {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  TEST = 'test',
  PRODUCTION = 'production',
}

export type V1RidaApiLocation = {
  lat: string
  lng: string
}

export type VForm = Vue & {
  validate: () => boolean
  resetValidation: () => boolean
  reset: () => void
}

export type V1RidaApiVehicleType = {
  id: number
  name: string
}

export type V1RidaApiUserRoleType = {
  id: number
  name: string
}

export type V1RidaApiBank = {
  id: string
  name: string
}

export type V1RidaApiUserRole = {
  id: string
  user_role_type?: V1RidaApiUserRoleType
  user_role_type_id: number
}

export type V1RidaApiUserVehicle = {
  id: string
  user_id: string
  vehicle_registration_number: string
  vehicle_type?: V1RidaApiVehicleType
  vehicle_type_id: number
}

export type V1RidaApiUserBankAccount = {
  id: string
  bank?: V1RidaApiBank
  bank_account_number: string
  bank_id: number
}

export type V1RidaApiGroup = {
  id: string
  code: string
  name: string
  config: {
    earning_config?: {
      earning_per_stop?: {
        driver_earning?: {
          per_total_stops?: {
            value: string
            type: string
            total_stop_from: number
            total_stop_to: number
          }[]
        }
        owner_earning?: {
          per_total_stops?: {
            value: string
            type: string
            total_stop_from: number
            total_stop_to: number
          }[]
        }
      }
    }
  } | null
}

export type V1RidaApiUserGroup = {
  id: string
  group_id: string
  user_id: string
  group?: V1RidaApiGroup
}

export type V1RidaApiUser = {
  id: string
  first_name: string | null
  last_name: string | null
  preferred_name: string | null
  created_at: string
  email: string
  dob: string | null
  nric: string | null
  phone_country_code: string | null
  phone_number: string | null
  user_status_type_id: number
  is_auto_assign_enabled: boolean
  user_accepted_job_status_type_id: number
  avatar_url: string | null
  user_vehicles?: V1RidaApiUserVehicle[]
  user_roles?: V1RidaApiUserRole[]
  user_bank_accounts?: V1RidaApiUserBankAccount[]
  user_groups?: V1RidaApiUserGroup[]
  config: {
    equipment: {
      has_thermal_bag: boolean
      has_trolley: boolean
      has_been_trained_by_ops: boolean
    }
    preference?: {
      preferred_delivery_zones: string[]
    }
    ban_from_group_ids?: string[]
    is_cpf_enabled?: boolean
  } | null
}

export type V1RidaApiFileUpload = {
  id: string
  url: string
  document_type_id: number
  file_upload_status_type_id: number
  created_at: string
}

export type V1RidaApiOrder = {
  id: string
  vehicle_type_id: number
  delivery_date: string
  order_delivery_type_id: number
}

export type V1RidaApiStop = {
  id: string
  delivery_window_start: string | null
  delivery_window_end: string | null
  pickup_time: string
  stop_type_id: number
  address: string
  postal_code: string | null
  unit_number: string | null
  latitude: string
  longitude: string
  name: string | null
  phone_country_code: string | null
  phone_number: string | null
  position: number
  item: Record<string, unknown> | null
  delivery_instruction: string | null
  admin_instruction: string | null
  merchant_reference_number: string | null
  order?: V1RidaApiOrder
  stop_pickup_id: string | null
  price: { value: string }
  pin_code: string | null
  region: string | null
  qr_code: string | null
  additional_info: Record<string, unknown> | null
  item_category_type_id: number | null
  driver_equipment_type_id: number | null
  group?: V1RidaApiGroup
}

export type V1RidaApiDriverEarning = {
  id: string
  task_id: string
  amount: { value: string }
  gross_amount: { value: string }
  comment: string | null
  driver_earning_type_id: number
}

export type V1RidaApiTask = {
  id: string
  stop_id: string
  job_id: string
  start_time: string | null
  end_time: string | null
  delivery_scheduled_time: string
  live_delivery_scheduled_time: string | null
  failed_reason_type_id: number | null
  comment: string | null
  task_delivery_status_type_id: number
  stop?: V1RidaApiStop
  delivery_scheduled_total_distance_travel: {
    value: number
    text: string
  }
  delivery_scheduled_total_time: {
    value: number
    text: string
  }
  delivery_state: {
    is_completed: boolean
  }
  file_uploads: V1RidaApiFileUpload[]
  driver_earnings: V1RidaApiDriverEarning[]
  action_status: {
    is_cancellable: boolean
    is_completable: boolean
  }
}

export type V1RidaApiJob = {
  id: string
  delivery_date: string
  code: string
  job_status_type_id: number
  assigned_to_user_id: string
  driver_notice_text: string | null
  assigned_time: string | null
  delivery_scheduled_total_distance_travel: {
    value: number
    text: string
  }
  delivery_scheduled_total_time: {
    value: number
    text: string
  }
  delivery_scheduled_completed_time: string
  tasks: V1RidaApiTask[]
  assigned_to_user?: V1RidaApiUser
  action_status: {
    is_cancellable: boolean
    is_completable: boolean
    is_driver_cancellable: boolean
    is_assignable: boolean
    is_arrangeable: boolean
    is_offerable: boolean
  }
  additional_info?: {
    route_id?: string | null
  }
}

export type V1RidaApiGetUserMeResponse = {
  data: {
    user: V1RidaApiUser
  }
}

export type V1RidaApiPostUserResponse = {
  data: {
    user: V1RidaApiUser
  }
}

export type V1RidaApiGetUserResponse = {
  data: {
    users: (V1RidaApiUser & {
      latest_user_location_time: string | null
      banned_groups: V1RidaApiGroup[]
    })[]
    total_users: number
    page_number: number
  }
}

export type V1RidaApiGetUserOnlineResponse = {
  data: {
    users: (V1RidaApiUser & {
      location: { latitude: string; longitude: string } | null
    })[]
    total_users: number
  }
}

export type V1RidaApiGetUserSearchResponse = {
  data: {
    users: V1RidaApiUser[]
    total_users: number
    page_number: number
  }
}

export type V1RidaApiPostMerchantUserResponse = {
  data: {
    is_user_exist_on_rida_platform: boolean
  }
}

export type V1RidaApiPutUserBulkResponse = {
  data: {
    warnings: {
      errors: string[]
      email: string
    }[]
  }
}

export type V1RidaApiGetUserGroupResponse = {
  data: {
    groups: V1RidaApiGroup[]
  }
}

export type V1RidaApiGetJobModel = V1RidaApiJob & {
  assigned_to_user: {
    last_location: { latitude: string; longitude: string } | null
  }
  total_comments: number
  tasks: (V1RidaApiTask & {
    late_reason: string | null
    failed_delivery_reason: string | null
  })[]
}

export type V1RidaApiGetJobResponse = {
  data: {
    total_jobs: number
    groups: V1RidaApiGroup[]
    total_online_users: number
    jobs: V1RidaApiGetJobModel[]
    assigned_users: V1RidaApiUser[]
  }
}

export type V1RidaApiGetJobAssignmentResponse = {
  data: {
    total_jobs: number
    groups: V1RidaApiGroup[]
    jobs: (V1RidaApiJob & {
      total_estimated_earning: { value: string }
      use_driver_eff_to_calculate_offer_price: boolean
    })[]
    assigned_users: V1RidaApiUser[]
  }
}

export type V1RidaApiPutJobAssignmentAssignUserResponse = {
  data: {
    jobs?: {
      code: string
      errors: string[]
    }[]
  }
}

export type V1RidaApiPutJobAssignmentAssignUserErrorResponse = {
  code: string
  message: string
  data: {
    jobs?: {
      code: string
      errors: string[]
    }[]
  }
}

export type V1RidaApiPutJobAssignmentRecalculateTimingErrorResponse = {
  code: string
  message: string
  data: {
    tasks?: {
      code: string
      errors: string[]
    }[]
  }
}

export type V1RidaApiPutJobAssignmentRecalculateTimingResponse = {
  data: {
    jobs: {
      id: string
      tasks: {
        id: string
        distance: number
        duration: number
        arrival_time: string | null
      }[]
    }[]
  }
}

export type V1RidaApiPutJobAssignmentOptimizeTaskSequenceResponse = {
  data: {
    jobs: {
      id: string
      tasks: {
        id: string
      }[]
    }[]
  }
}

export type V1RidaApiGetJobIncompleteResponse = {
  data: {
    total_jobs: number
    page_number: number
    jobs: V1RidaApiJob[]
  }
}

export type V1RidaApiPostUserBulkErrorResponse = {
  code: string
  message: string
  data: {
    users?: {
      email: string
      errors: string[]
    }[]
    errors?: {
      field: string
      message: string
    }[]
  }
}

export type V1RidaApiGetDriverEarningResponse = {
  data: {
    total_jobs: number
    page_number: number
    jobs: (V1RidaApiJob & {
      total_estimated_earning: { value: string }
      use_driver_eff_to_calculate_offer_price: boolean
    })[]
  }
}

export type V1RidaApiGetAidaChatAvailableDriverResponse = {
  data: {
    users: {
      user: V1RidaApiUser & {
        total_earning: string
        total_duration: { text: string }
      }
      jobs: (V1RidaApiJob & {
        total_earning: string
        total_duration: { text: string }
      })[]
    }[]
    not_assigned_jobs: V1RidaApiJob[]
  }
}

export type V1RidaApiPostAidaChatOptimizeRouteResponse = {
  data: {
    stops: {
      id: string
    }[]
  }
}

export type V1RidaApiComment = {
  id: string
  description: string
  created_by: V1RidaApiUser
  created_at: string
}

export type V1RidaApiGetJobIdCommentResponse = {
  data: {
    comments: V1RidaApiComment[]
  }
}

export type V1RidaApiGetFileUploadUrlResponse = {
  data: {
    uploads: {
      url: string
      id: string
    }[]
  }
}

export type V1RidaApiGetFileUploadCheckedImageResponse = {
  data: {
    file_uploads: V1RidaApiFileUpload[]
    total: number
    page_number: number
  }
}

export type V1RidaApiTaskDeliveryStatusType = {
  id: number
  name: string
}

export type V1RidaApiJobStatusType = {
  id: number
  name: string
}

export type V1RidaApiOrderServiceType = {
  id: number
  name: string
}

export type V1RidaApiJobHistory = {
  id: string
  job_id: string
  from_assigned_to_user_id: string | null
  to_assigned_to_user_id: string | null
  from_job_status_type_id: number | null
  to_job_status_type_id: string | null
  job: V1RidaApiJob
  from_assigned_to_user?: V1RidaApiUser
  to_assigned_to_user?: V1RidaApiUser
  from_job_status_type?: V1RidaApiJobStatusType
  to_job_status_type?: V1RidaApiJobStatusType
  created_by_id: string | null
  created_at: string
  created_by?: V1RidaApiUser
}

export type V1RidaApiGetJobIdHistoryResponse = {
  data: {
    total_job_histories: number
    page_number: number
    job: V1RidaApiJob
    job_histories: V1RidaApiJobHistory[]
  }
}

export type V1RidaApiGetNotificationSidebarResponse = {
  data: {
    total_incomplete_jobs: number
  }
}

export type V1RidaApiGetReportEarningForDateRangeResponse = {
  data: {
    report_content: string
  }
}

export type V1RidaApiGetLateJobResponse = {
  data: {
    report_content: string
  }
}

export type V1RidaApiGetReportJobPerHourResponse = {
  data: {
    report_content: string
  }
}

export type V1RidaApiGetReportJobPerDayTimelineResponse = {
  data: {
    assigned_jobs: {
      user: V1RidaApiUser & {
        location: { latitude: string; longitude: string } | null
        vehicle_type: V1RidaApiVehicleType | null
        total_duration: { text: string }
        total_earning: { value: string }
        total_distance: { text: string }
      }
      jobs: (V1RidaApiJob & {
        vehicle_type: V1RidaApiVehicleType | null
        total_duration: { text: string }
        total_earning: { value: string }
        total_distance: { text: string }
      })[]
    }[]
    unassigned_jobs: {
      jobs: (V1RidaApiJob & {
        vehicle_type: V1RidaApiVehicleType | null
        total_duration: { text: string }
        total_earning: { value: string }
        total_distance: { text: string }
      })[]
      merchant: {
        id: string
        name: string
      }
    }[]
    total_earning: { value: string }
  }
}

export type V1RidaApiPostFileUploadDownloadPodCheckReportResponse = {
  data: {
    report_content: string
  }
}

export type V1RidaApiGetTaskIdFileUploadResponse = {
  data: {
    file_uploads: V1RidaApiFileUpload[]
  }
}

export type V1RidaApiGetJobIdDriverEarningResponse = {
  data: V1RidaApiJob & { total_estimated_earning: { value: string } }
}

export type V1RidaApiDriverEarningType = {
  id: number
  name: string
  sign_type_id: number
  position: number
}

export type V1RidaApiGetTypeResponse = {
  data: {
    driver_earning_types: V1RidaApiDriverEarningType[]
    vehicle_types: V1RidaApiVehicleType[]
    task_delivery_status_types: V1RidaApiTaskDeliveryStatusType[]
    job_status_types: V1RidaApiJobStatusType[]
    user_role_types: V1RidaApiUserRoleType[]
    banks: V1RidaApiBank[]
    order_service_types: V1RidaApiOrderServiceType[]
    platform_vehicle_types: string[]
    regions: {
      id: string
      name: string
    }[]
    specific_regions: {
      id: string
      name: string
      description: string
    }[]
  }
}

export type V1RidaApiErrorResponse = {
  code: string
  message: string
  data?: {
    errors?: {
      field: string
      message: string
    }[]
  }
}

export type V1RidaApiGetGroupResponse = {
  data: {
    total_groups: number
    page_number: number
    groups: V1RidaApiGroup[]
  }
}

export type V1RidaApiGroupToGroupMapping = {
  id: string
  from_group_id: string
  to_group_id: string
  from_group?: V1RidaApiGroup
  to_group?: V1RidaApiGroup
  config: {
    assignment_config?: {
      is_auto_assign_enabled: boolean
    }
    earning_config?: {
      late_penalty?: {
        enabled: boolean
        whole_route_amount_per_charge?: {
          value: string
          type: string
        }
        max_whole_route_amount_can_charge?: {
          value: string
          type: string
        }
        grace_period_in_minute: number
        max_amount_per_stop_can_charge?: {
          value: string
          type: string
        }
        amount_per_stop_charge?: {
          value: string
          type: string
        }
        pickup_buffer_in_minute?: number
      }
      earning_per_stop?: {
        driver_earning?: {
          default: {
            value: string
            type: string
            min_earned?: string | null
          }
          per_vehicle_types: {
            value: string
            order_vehicle_type_id: number
            type: string
          }[]
        }
        owner_earning?: {
          default: {
            value: string
            type: string
            min_earned?: string | null
          }
          per_vehicle_types: {
            value: string
            order_vehicle_type_id: number
            type: string
          }[]
        }
      }
    }
  } | null
}

export type V1RidaApiGetGroupIdGroupToGroupMappingResponse = {
  data: {
    total_group_to_group_mappings: number
    page_number: number
    group_to_group_mappings: V1RidaApiGroupToGroupMapping[]
  }
}

export type V1RidaApiGetGroupIdResponse = {
  data: V1RidaApiGroup
}

// sass fleet operator
export type V1RidaApiGetSassFleetOperatorJobIdDriverEarningResponse =
  V1RidaApiGetJobIdDriverEarningResponse
export type V1RidaApiGetSassFleetOperatorDriverEarningResponse =
  V1RidaApiGetDriverEarningResponse
export type V1RidaApiGetSassFleetOperatorFileUploadUrlResponse =
  V1RidaApiGetFileUploadUrlResponse
export type V1RidaApiGetSassFleetOperatorJobResponse = V1RidaApiGetJobResponse
export type V1RidaApiGetSassFleetOperatorJobAssignmentResponse =
  V1RidaApiGetJobAssignmentResponse
export type V1RidaApiPutSassFleetOperatorJobAssignmentAssignUserResponse =
  V1RidaApiPutJobAssignmentAssignUserResponse
export type V1RidaApiGetSassFleetOperatorReportEarningForDateRangeResponse =
  V1RidaApiGetReportEarningForDateRangeResponse
export type V1RidaApiGetSassFleetOperatorTypeResponse = V1RidaApiGetTypeResponse
export type V1RidaApiGetSassFleetOperatorUserGroupResponse =
  V1RidaApiGetUserGroupResponse
export type V1RidaApiGetSassFleetOperatorUserOnlineResponse =
  V1RidaApiGetUserOnlineResponse
export type V1RidaApiGetSassFleetOperatorUserResponse = V1RidaApiGetUserResponse
export type V1RidaApiGetSassFleetOperatorUserSearchResponse =
  V1RidaApiGetUserSearchResponse
export type V1RidaApiSassFleetOperatorDriverEarningType =
  V1RidaApiDriverEarningType
export type V1RidaApiSassFleetOperatorJob = V1RidaApiJob
export type V1RidaApiSassFleetOperatorJobStatusType = V1RidaApiJobStatusType
export type V1RidaApiSassFleetOperatorTaskDeliveryStatusType =
  V1RidaApiTaskDeliveryStatusType
export type V1RidaApiSassFleetOperatorTask = V1RidaApiTask
export type V1RidaApiSassFleetOperatorFileUpload = V1RidaApiFileUpload
export type V1RidaApiSassFleetOperatorOrderServiceType =
  V1RidaApiOrderServiceType
export type V1RidaApiSassFleetOperatorGroup = V1RidaApiGroup
export type V1RidaApiSassFleetOperatorUser = V1RidaApiUser
export type V1RidaApiSassFleetOperatorVehicleType = V1RidaApiVehicleType
export type V1RidaApiPutSassFleetOperatorJobAssignmentAssignUserErrorResponse =
  V1RidaApiPutJobAssignmentAssignUserErrorResponse
export type V1RidaApiSassFleetOperatorBank = V1RidaApiBank
export type V1RidaApiSassFleetOperatorUserRoleType = V1RidaApiUserRoleType
export type V1RidaApiPostSassFleetOperatorUserBulkErrorResponse =
  V1RidaApiPostUserBulkErrorResponse
export type V1RidaApiSassFleetOperatorUserGroup = V1RidaApiUserGroup
export type V1RidaApiSassFleetOperatorDriverEarning = V1RidaApiDriverEarning
