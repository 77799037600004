import { Dayjs } from 'dayjs'
import _ from 'lodash'

import { DEFAULT_FORMAT, JOB_STATUS_TYPE } from './Constant'
import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetDriverEarningResponse,
  V1RidaApiGetJobAssignmentResponse,
  V1RidaApiGetJobIncompleteResponse,
  V1RidaApiGetJobResponse,
  V1RidaApiPutJobAssignmentAssignUserResponse,
  V1RidaApiPutJobAssignmentOptimizeTaskSequenceResponse,
  V1RidaApiPutJobAssignmentRecalculateTimingResponse,
} from './Type'

class Job {
  public static async getJobEarnings(
    date: Dayjs,
    filterOptions?: {
      pageNumber?: number
      itemsPerPage?: number
      search?: string
    },
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetDriverEarningResponse>(
        'v1/driverEarning',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
          search: filterOptions?.search,
        },
      )

    return response.data.data
  }

  public static async getAllJobs(
    date: Dayjs,
    filterOptions?: {
      search?: string
      jobStatusTypeIds?: number[]
      groupIds?: string[]
      orderServiceTypeIds?: number[]
      userIds?: string[]
    },
  ) {
    const response = await RidaApiService.get<V1RidaApiGetJobResponse>(
      'v1/job',
      {
        search: filterOptions?.search,
        date: date.format(DEFAULT_FORMAT.DATE),
        job_status_type_id: filterOptions?.jobStatusTypeIds,
        group_id: filterOptions?.groupIds,
        order_service_type_id: filterOptions?.orderServiceTypeIds,
        user_id: filterOptions?.userIds,
      },
    )

    return response.data.data
  }

  public static async getAllEarningsForJob(jobId: string) {
    const response =
      await RidaApiService.get<V1RidaApiGetDriverEarningResponse>(
        `v1/job/${jobId}/driverEarning`,
      )

    return response.data.data
  }

  public static isJobCompleted(jobStatusTypeId: number) {
    return _.includes(
      [
        JOB_STATUS_TYPE.CANCELLED_BY_ADMIN,
        JOB_STATUS_TYPE.CANCELLED_BY_DRIVER,
        JOB_STATUS_TYPE.CANCELLED_BY_CUSTOMER,
        JOB_STATUS_TYPE.COMPLETED,
      ],
      jobStatusTypeId,
    )
  }

  public static async updateJobDeliveryStatus(
    jobId: string,
    options: {
      jobStatusTypeId: number
      isDriverCancelled?: boolean
    },
  ) {
    await RidaApiService.put(`v1/job/${jobId}/deliveryStatus`, {
      is_driver_cancelled: options.isDriverCancelled,
      job_status_type_id: options.jobStatusTypeId,
    })
  }

  public static async updateJobsDeliveryStatus(options: {
    jobIds: string[]
    jobStatusTypeId: number
    isDriverCancelled?: boolean
  }) {
    await RidaApiService.put(`v1/job/deliveryStatus`, {
      is_driver_cancelled: options.isDriverCancelled,
      job_status_type_id: options.jobStatusTypeId,
      job_id: options.jobIds,
    })
  }

  public static async getAllIncompleteJobs(filterOptions?: {
    search?: string
    pageNumber?: number
    itemsPerPage?: number
  }) {
    const response =
      await RidaApiService.get<V1RidaApiGetJobIncompleteResponse>(
        'v1/job/incomplete',
        {
          search: filterOptions?.search,
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
        },
      )

    return response.data.data
  }

  public static async getAllAssignmentJobs(
    date: Dayjs,
    filterOptions?: {
      userId?: string[]
      jobStatusTypeIds?: number[]
      groupIds?: string[]
      orderServiceTypeIds?: number[]
    },
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetJobAssignmentResponse>(
        'v1/jobAssignment',
        {
          user_id: filterOptions?.userId,
          date: date.format(DEFAULT_FORMAT.DATE),
          job_status_type_id: filterOptions?.jobStatusTypeIds,
          group_id: filterOptions?.groupIds,
          order_service_type_id: filterOptions?.orderServiceTypeIds,
        },
      )

    return response.data.data
  }

  public static async assignUserForJobs(
    jobIds: string[],
    userId: string,
    ignoreWarnings = false,
  ) {
    const response =
      await RidaApiService.put<V1RidaApiPutJobAssignmentAssignUserResponse>(
        'v1/jobAssignment/assignUser',
        {
          user_id: userId,
          job_id: jobIds,
          ignore_warnings: ignoreWarnings,
        },
      )

    return response.data.data
  }

  public static async recalculateTimingJobAssignment(
    jobs: {
      id: string
      isOriginalJob: boolean
      code: string
      tasks: {
        id: string
        code: string
        scheduledTime: Dayjs
      }[]
    }[],
  ) {
    const response =
      await RidaApiService.put<V1RidaApiPutJobAssignmentRecalculateTimingResponse>(
        'v1/jobAssignment/recalculateTiming',
        {
          jobs: _.map(jobs, job => {
            return {
              id: job.id,
              code: job.code,
              is_original_job: job.isOriginalJob,
              tasks: _.map(job.tasks, task => {
                return {
                  code: task.code,
                  id: task.id,
                  scheduled_time: task.scheduledTime.toISOString(),
                }
              }),
            }
          }),
        },
      )

    return response.data.data
  }

  public static async arrangeTasksForJobAssignment(
    jobs: {
      id: string
      isOriginalJob: boolean
      code: string
      platformVehicleType: string | null
      driverId?: string | null
      tasks: {
        id: string
        code: string
        scheduledTime: Dayjs
        isCloned: boolean
      }[]
    }[],
  ) {
    await RidaApiService.put('v1/jobAssignment/arrangeTask', {
      jobs: _.map(jobs, job => {
        return {
          id: job.id,
          code: job.code,
          is_original_job: job.isOriginalJob,
          platform_vehicle_type: job.platformVehicleType,
          driver_id: job.driverId,
          tasks: _.map(job.tasks, task => {
            return {
              id: task.id,
              code: task.code,
              scheduled_time: task.scheduledTime.toISOString(),
              is_cloned: task.isCloned,
            }
          }),
        }
      }),
    })
  }

  public static async manuallySendOfferForJobs(jobIds: string[]) {
    await RidaApiService.put('v1/jobAssignment/offerJob', {
      job_id: jobIds,
    })
  }

  public static async optimizeTasks(
    jobs: {
      id: string
      tasks: {
        id: string
      }[]
    }[],
  ) {
    const response =
      await RidaApiService.put<V1RidaApiPutJobAssignmentOptimizeTaskSequenceResponse>(
        'v1/jobAssignment/optimizeTaskSequence',
        {
          jobs,
        },
      )

    return response.data.data
  }
}

export { Job as JobService }
